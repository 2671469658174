import { useNavigate } from 'react-router-dom';
import { useLoading } from './LoadingContext';

const useNavigateWithLoading = () => {
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const navigateWithLoading = (path) => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 100); 
  };

  return navigateWithLoading;
};

export default useNavigateWithLoading;
