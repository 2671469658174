import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import RouteWrapper from "./RouteWrapper";  

function App() {
    return (
        <Router>
            <RouteWrapper /> 
        </Router>
    );
}

export default App;
