import React, { useEffect } from "react";
import About_Image from "../images/image-about.jpeg";
import "./About.css";
import Founder_Icon from "../images/founder-image.jpeg";
import About_Slides_Data from "../utils/about-slides-data";
import Slider from "react-slick";
import { TeamCard } from "../components/About/TeamCard";

import FirstTeamMember from "../images/team/team-1.jpeg";
import SecondTeamMember from "../images/team/team-2.jpeg";
import ThirdTeamMember from "../images/team/team-3.jpeg";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const NextArrow = ({ onClick, currentSlide, slideCount }) => {
  return <div className="right-arrow" onClick={onClick}></div>;
};

const PrevArrow = ({ onClick, currentSlide }) => {
  return (
    <div
      className="left-arrow"
      onClick={() => {
        if (currentSlide > 0) onClick();
      }}
    ></div>
  );
};

const About = () => {
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);


  const data = About_Slides_Data;
  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 500,
    slidesToShow: data.length >= 2 ? 2 : 1, // Adjust slidesToShow based on the number of items in data
    rows: 1, // Adjust rows based on the number of items in data
    slidesToScroll: data.length >= 2 ? 2 : 1, // Adjust slidesToScroll based on the number of items in data
    dots: true,
    autoplay: true,
    autoplaySpeed: 3500,
    infinite:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };


  const { ref, inView } = useInView({
    threshold: 0.9,
    triggerOnce: true, // Add triggerOnce option to ensure animation triggers only once
  });
  const helper = (inView) => {
    return inView ? "animate" : "";
  };

  return (
    <div className="about">
      <h1 className="text-center text-white">About Us</h1>
      <div className="image-undertext-container">
        <img className="about-image" src={About_Image} alt="about-image" />
        <div className="under-image-container">
          <div className="part-1">
            Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor
            magna aliqua volupte dolor.
            <div className="ceo-div">
              <img src={Founder_Icon} alt="founder image" />
              <div className="founder-right-text">
                <h2>Rojer Moore</h2>
                <p>CEO director</p>
              </div>
            </div>
          </div>
          <div className="part-2-3-container">
            <div className="part-2">
              nsectetur adipiscing elit, sed do eiusm onsectetur adipiscing
              elit,eiusm od tempor ut labore. Phasellus quis sapien nunc. <br />{" "}
              Consectetur adipiscing elit, sed do eiusm onsectetur adipiscing
              elit, sed do eiusm od tempor ut labore sed adipiscing  do eiusm od
              tempor ut sed do eiusm.
            </div>
            <div className="part-3">
              Consectetur adipiscing elit, sed do eiusm onsectetur adipiscing
              elit, sed do od tempor ut labore.
              <br />
              Consectetur adipiscing elit, sed do eiusm onsectetur adipiscing
              elit, sed do eiusm od tempor.
            </div>
          </div>
        </div>
      </div>
      <div className="animated-text" >
        <h1>
          Revolutionizing . Innovative . Empowering . Revolutionizing .
          Innovative . Empowering .
          {/* Revolutionizing . Innovative . Empowering . */}
        </h1>
      </div>
      <div className="about-slider">
        <Slider {...settings}>
          {data.map((item, i) => {
            return (
              <div key={i} className="about-slide">
                <img src={item} alt={`image-${i}`} />
              </div>
            );
          })}
        </Slider>
      </div>

      <div className={`under-slider-text ${helper(inView)}`} ref={ref}>
        <h2>
          Branding, websites and digital experiences, crafted with love,
          intelligence, precision and creativity.
        </h2>
        <h4>Peter Hamzah</h4>
        <span>Creative Director</span>
      </div>

      <div className="team">
          <h1>Our Team</h1>
          <div className="team-container">
            <TeamCard image={FirstTeamMember} name={"John Lewis"} position={"Photographer"}/>
            <TeamCard image={SecondTeamMember} name={"Jane Lee"} position={"Art Director"}/>
            <TeamCard image={ThirdTeamMember} name={"Nicky Carter"} position={"Project Manager"}/>
          </div>
      </div>
    </div>
  );
};

export default About;
