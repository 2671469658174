import React from 'react'

export const TeamCard = ({image,name,position}) => {
  return (
    <div className='team-card'>
        <img src={image}/>
        <h2>{name}</h2>
        <span>{position}</span>
    </div>
  )
}
