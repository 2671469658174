import image1 from '../images/services-slides/1.png';
import image2 from '../images/services-slides/2.png';
import image3 from '../images/services-slides/3.png';
import image4 from '../images/services-slides/4.png';
import image5 from '../images/services-slides/5.png';
import image6 from '../images/services-slides/6.png';
import image7 from '../images/services-slides/7.png';
import image8 from '../images/services-slides/8.png';
import image9 from '../images/services-slides/9.png';
import image10 from '../images/services-slides/10.png';
import image11 from '../images/services-slides/11.png';
import image12 from '../images/services-slides/12.png';
import image13 from '../images/services-slides/13.png';
import image14 from '../images/services-slides/14.png';
import image15 from '../images/services-slides/15.png';
import image16 from '../images/services-slides/16.png';
import image17 from '../images/services-slides/17.png';
import image18 from '../images/services-slides/18.png';
import image19 from '../images/services-slides/19.png';
import image20 from '../images/services-slides/20.png';
import image21 from '../images/services-slides/21.png';
import image22 from '../images/services-slides/22.png';
import image23 from '../images/services-slides/23.png';
import image24 from '../images/services-slides/24.png';
import image25 from '../images/services-slides/25.png';
import image26 from '../images/services-slides/26.png';
import image27 from '../images/services-slides/27.png';

const slides_data = [
  { image: image1, para: "Web and App Development", link: "" },
  { image: image2, para: "Customer Experience Enhancement", link: "" },
  { image: image3, para: "Events Management", link: "" },
  { image: image4, para: "Data-Driven Market Analysis", link: "" },
  { image: image5, para: "Interactive and Digital Design", link: "" },
  { image: image6, para: "Customer Experience Enhancement", link: "" },
  { image: image7, para: "Printing Services", link: "" },
  
  { image: image8, para: "Innovative Product Launch Strategies", link: "" },
  { image: image9, para: "Video Production and Animation", link: "" },
  { image: image10, para: "Emotionally-Driven Campaign", link: "" },
  { image: image11, para: "Collateral Design", link: "" },
  { image: image12, para: "Social Media Management", link: "" },
  { image: image13, para: "Production", link: "" },
  { image: image14, para: "Content Creation & Management", link: "" },
  { image: image15, para: "Marketing Strategy", link: "" },
  { image: image16, para: "Brand Development", link: "" },
  { image: image17, para: "Digital Marketing & SEO", link: "" },
  { image: image18, para: "Videography", link: "" },
  { image: image19, para: "Packaging Design", link: "" },
  { image: image20, para: "E-commerce Solutions", link: "" },
  { image: image21, para: "Corporate Identity Development", link: "" },
  { image: image22, para: "Branding and Rebranding", link: "" },
  { image: image23, para: "Social Media Platform Management", link: "" },
  { image: image24, para: "Graphic Design", link: "" },
  { image: image25, para: "Platform Development and Management", link: "" },
  { image: image26, para: "UX/UI Designing", link: "" },
  { image: image27, para: "Business Plans", link: "" },
];

export default slides_data;