import React from "react";
import Logo from "../images/footer-logo.png";
import { NavLink, useLocation } from "react-router-dom";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import useNavigateWithLoading from "../utils/useNavigateWithLoading";

const Footer = () => {
  const location = useLocation();
  const navigateWithLoading = useNavigateWithLoading();

  const handleNavigation = (path) => {
    navigateWithLoading(path);
  };

  const activelink = ({ isActive }) => (isActive ? "active-nav-link" : "");

  return (
    <div className="footer-custom">
      <img src={Logo} alt="logo-footer" />
      <div className="footer-links">
        <NavLink
          to="/Voltique"
          className={({ isActive }) =>
            `nav-link ${isActive || location.pathname === "/" ? "active-nav-link" : ""}`
          }
          onClick={() => handleNavigation("/Voltique")}
        >
          Home Page
        </NavLink>
        <NavLink
          to="/about"
          className={activelink}
          onClick={() => handleNavigation("/about")}
        >
          About Us
        </NavLink>
        <NavLink
          to="/contact"
          className={activelink}
          onClick={() => handleNavigation("/contact")}
        >
          Contact Us
        </NavLink>
      </div>
      <div className="footer-social-links">
        <div className="social-link">
          <FontAwesomeIcon
            className="icon-social-link face"
            icon={faFacebookF}
          />
        </div>
        <div className="social-link">
          <FontAwesomeIcon
            className="icon-social-link insta"
            icon={faInstagram}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
