import React from 'react'
import './Skill.css'
const Skill = ({info,custom_class}) => {
  return (
    <div className='skill-outer'>
        <div className={`skill-inner ${custom_class}`}>
            <p>{info}</p>
        </div>
    </div>
  )
}

export default Skill