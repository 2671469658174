import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import LoadingComponent from "./components/LoadingComponent";
import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useLoading } from "./utils/LoadingContext";
function RouteWrapper() {
  const { isLoading, setIsLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
  }, [location.pathname]);

  return (
    <>
      <LoadingComponent
        isNavigating={location.key}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      {!isLoading && (
        <>
          <Navbar />

          <Routes>
            <Route path="/" default element={<Home />} />
            <Route path="/Voltique" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default RouteWrapper;
