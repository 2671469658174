import React from "react";
import "./Section1_home.css";
import arrow from "../../images/arrow-down.png"
const Section1_home = () => {
  return (
    <section className="section-1-home">
      <div className="centered-div-section-1-home">
        <div className="left-pink-shape"></div>
        <button className=" rounded-3xl">WELCOME TO VOLTIQUE</button>
        <h1 className="header-section-1-home">
          Your Path to Digital Excellence
        </h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo earum fugiat aperiam. Aliquam obcaecati assumenda eos reprehenderit neque sit cupiditate iure quasi id officiis, reprehenderit rerum inventore eius iusto, delectus non distinctio ea. Ratione, reprehenderit? Tenetur, soluta quisquam?
        </p>
        {/* <div className="arrow-down-div"><img src={arrow}/></div> */}
        <div className="right-blue-shape"></div>
      </div>
    </section>
  );
};

export default Section1_home;
