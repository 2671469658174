import Image1 from "../images/about-slides/1.jpeg"
import Image2 from "../images/about-slides/2.jpeg"
import Image3 from "../images/about-slides/3.jpeg"


const About_Slides_Data = [
    Image1,
    Image2,
    Image3
]


export default About_Slides_Data;