import React, { useEffect } from "react";
import "./Home.css";
import Section1_home from "../components/Home/Section1_home";
import Section2_home from "../components/Home/Section2_home";
import Section4_home from "../components/Home/Section4_home";
import Section3_home from "../components/Home/Section3_home";
import Services_Home from "../components/Home/Services_Home";
import Skill from "../components/Home/Skill";
import { useLocation } from "react-router-dom";
import { useLoading } from "../utils/LoadingContext";
function Home() {
  const { isLoading, setIsLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return (
    <div className="home">
      <Section1_home />
      <Section2_home />
      <Section3_home />
      <Section4_home />
      <Services_Home />
      <section className="skills">
        <h1 className="text-white text-center">Our Values</h1>
        <div className="skills-container">
          <Skill info={"Innovation and Creativity"} />
          <Skill info={"Integrity and Transparency"} />
          <Skill info={"Collaboration and Teamwork"} />
          <Skill info={"Leadership and Empowerment"} />
          <Skill custom_class={"pink-diamond"} info={"Customer-Centricity"} />
          <Skill
            custom_class={"pink-diamond"}
            info={"Excellence and Quality"}
          />
          <Skill
            custom_class={"pink-diamond"}
            info={"Sustainability and Responsibility"}
          />
        </div>
      </section>
    </div>
  );
}

export default Home;
