import React, { useState, useEffect } from "react";
import "./Contact.css";
import beside_contact_image from "../images/beside-contact.jpeg";
import NameIcon from "../images/icons/name.png";
import PhoneIcon from "../images/icons/phone.png";
import EmailIcon from "../images/icons/email.png";
import SubjectIcon from "../images/icons/subject.png";
import MessageIcon from "../images/icons/message.png";
import ContactIcon from "../images/icons/contact.png";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState("");

  useEffect(() => {
    const shouldUseSmooth = document.body.scrollHeight > window.innerHeight;
    window.scrollTo({
      top: 0,
      behavior: shouldUseSmooth ? "smooth" : "auto",
    });
  }, [location.pathname]);

  const sendEmail = () => {
    const emailRecipient = "info@example.com";
    const sub = `Dear Voltique, I'm ${name}\n\nI'm writing you regarding the following:\n\n`;
    const body = `${message}\n\nEmail: ${email}\n\nPhone: ${phone}\n\nBest,\n${name}`;
    const mailtoLink = `mailto:${emailRecipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(sub + body)}`;
    window.location.href = mailtoLink;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !phone || !email || !subject || !message) {
      setFormError("Please fill in all required fields.");
    } else {
      setFormError("");
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");
      sendEmail();
    }
  };

  return (
    <div className="contact">
      <div className="container-for-image-contactform">
        <div className="beside-form-div">
          <h2 className="contact-header">
            Have a <span>cool project?</span>
            <br />
            Get in touch!
          </h2>
          <img src={beside_contact_image} alt="image of robot" />
        </div>
        <div className="contact-form">
          <div className="input-container">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <i className="name-icon">
              <img src={NameIcon} alt="name-icon" />
            </i>{" "}
          </div>
          <div className="input-container">
            <input
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <i className="phone-icon">
              <img src={PhoneIcon} alt="phone-icon" />
            </i>{" "}
          </div>
          <div className="input-container">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <i className="email-icon">
              <img src={EmailIcon} alt="email-icon" />
            </i>{" "}
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <i className="subject-icon">
              <img src={SubjectIcon} alt="subject-icon" />
            </i>{" "}
          </div>
          <div className="input-container">
            <i className="message-icon">
              <img src={MessageIcon} alt="message-icon" />
            </i>{" "}
            <textarea
              className="message-input"
              placeholder=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          {formError && <div className="form-error">{formError}</div>}
          <button className="contact-button" onClick={handleSubmit}>
            <span>
              <img src={ContactIcon} alt="contact-icon" />
            </span>
            Get In Touch
          </button>
        </div>
      </div>
      <div className="contact-info-container">
        <div className="contact-info">
          <h2>01</h2>
          <p>+961 03 648 946</p>
        </div>
        <div className="contact-info">
          <h2>02</h2>
          <p>Beirut Hazmieh</p>
        </div>
        <div className="contact-info">
          <h2>03</h2>
          <p>info@example.com</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
