import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Services_Home.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image1 from "../../images/image-section4-home.jpeg";
import Slide from "./Slide";
import { Link } from "react-router-dom";
import slides_data from "../../utils/services-slides-data";


const NextArrow = ({ onClick, currentSlide, slideCount, slidesToShow, rows,totalLength }) => {
  const totalBatchesSlides = Math.floor(totalLength / (slidesToShow * rows));
  const lastInitiatingSlideIndex = slidesToShow * totalBatchesSlides;
  if (currentSlide >= lastInitiatingSlideIndex) {
    return null;
  }

  return <div className="right-arrow" onClick={onClick}></div>;
};

const PrevArrow = ({ onClick, currentSlide }) => {
  if (currentSlide === 0) {
    return null;
  }
  return (
    <div
      className="left-arrow"
      onClick={() => {
        if (currentSlide > 0) onClick();
      }}
    ></div>
  );
};

const Services_Home = () => {
  const data = slides_data;
  const slidesToShowConfig = data.length > 2 ? 3 : 1;
  const rowsConfig = data.length > 5 ? 2 : 1;

  const settings = {
    nextArrow: <NextArrow totalLength={data.length} slidesToShow={slidesToShowConfig} rows={rowsConfig} />,
    prevArrow: <PrevArrow />,
    speed: 500,
    slidesToShow: slidesToShowConfig,
    rows: rowsConfig,
    slidesToScroll: data.length > 2 ? 3 : 1,
    autoplay: true,
    autoplaySpeed: 4500,
    infinite: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  return (
    <section className="services-home">
      <h2 className="services-home-header text-center font-black">OUR SERVICES</h2>
      <div className="services-slider">
        <Slider {...settings}>
          {data.map((item, i) => {
            return (
              <Slide image={item.image} para={item.para} link={item.link} key={i} />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Services_Home;
