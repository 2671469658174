import React, { useEffect, useState } from "react";
import Logo from "../images/Voltique Brand Identity-6 2.png";
import Loading from "../images/loading.gif";
const LoadingComponent = ({ isNavigating,setIsLoading,isLoading }) => {
//   const [isLoading, setIsLoading] = useState(false);

  const [isFirstVisit, setisFirstVisit] = useState(
    sessionStorage.getItem("hasVisited")
  );

  useEffect(() => {
    setIsLoading(true);
    document.documentElement.style.overflow = "hidden";

    console.log(isFirstVisit);

    setTimeout(
      () => {
        setIsLoading(false);
        document.documentElement.style.overflow = "auto";
        document.documentElement.style.overflowX = "hidden";

        if (!isFirstVisit) {
          sessionStorage.setItem("hasVisited", "true");
          setisFirstVisit(sessionStorage.getItem("hasVisited"));
        }
      },
      isFirstVisit ? 2500 : 3600
    );
  }, [isNavigating]);

  if (!isLoading) {
    return null; // Don't render anything if not loading
  }

  return (
    <div className={`loading-screen ${!isFirstVisit ? "welcome" : ""}`}>
      {!isFirstVisit ? (
        <>
          <div className="middle-of-screen">
            <h3 className="first">
              <span style={{ "--animation-delay": "3" }}>W</span>
              <span style={{ "--animation-delay": "4" }}>e</span>
              <span style={{ "--animation-delay": "5" }}>l</span>
              <span style={{ "--animation-delay": "6" }}>c</span>
              <span style={{ "--animation-delay": "7" }}>o</span>
              <span style={{ "--animation-delay": "8" }}>m</span>
              <span style={{ "--animation-delay": "9" }}>e</span>{" "}
            </h3>
            <h3 className="second">
              <span style={{ "--animation-delay": "11" }}>T</span>
              <span style={{ "--animation-delay": "12" }}>o</span>
            </h3>
            <h2 className="third">
              <span style={{ "--animation-delay": "15" }}>V</span>
              <span style={{ "--animation-delay": "16" }}>o</span>
              <span style={{ "--animation-delay": "17" }}>l</span>
              <span style={{ "--animation-delay": "18" }}>t</span>
              <span style={{ "--animation-delay": "19" }}>i</span>
              <span style={{ "--animation-delay": "20" }}>q</span>
              <span style={{ "--animation-delay": "21" }}>u</span>
              <span style={{ "--animation-delay": "22" }}>e</span>
            </h2>
          </div>
        </>
      ) : (
        <>
          <div className="middle-of-screen">
            <h2 className="alone">
              <span style={{ "--animation-delay": "0" }}>V</span>
              <span style={{ "--animation-delay": "3" }}>o</span>
              <span style={{ "--animation-delay": "6" }}>l</span>
              <span style={{ "--animation-delay": "7" }}>t</span>
              <span style={{ "--animation-delay": "8" }}>i</span>
              <span style={{ "--animation-delay": "9" }}>q</span>
              <span style={{ "--animation-delay": "10" }}>u</span>
              <span style={{ "--animation-delay": "11" }}>e</span>
            </h2>
          </div>
        </>
      )}
    </div>
  );
};

export default LoadingComponent;
