import React, { useEffect, useState } from "react";
import "./Section2_home.css";
import SideImage from "../../images/image-section2-home.jpeg";
import { useInView } from "react-intersection-observer";
const Section2_home = () => {
  const [threshold, setThreshold] = useState(0.3); // Initial threshold value
  const { ref, inView } = useInView({
    threshold: threshold,
    triggerOnce: true, // Add triggerOnce option to ensure animation triggers only once
  });

  const updateThreshold = () => {
    const newThreshold = window.innerWidth < 1001 ? 0.35 : 0.25; // Set threshold based on screen width
    setThreshold(newThreshold);
  };
  useEffect(() => {
    updateThreshold();
    window.addEventListener("resize", updateThreshold);
    return () => {
      window.removeEventListener("resize", updateThreshold);
    };
  }, []);

  const helper = (inView) => {
    return inView ? "animate" : "";
  };

  return (
    <section className={`section-2-home`} ref={ref}>
      <div className="container-section-2-home">
        <div className="row-flex">
          <div className={`flex-it ${helper(inView)}`}>
            <div className="section-2-home-text">
              <h2>A B O U T <span className=" inline-block w-3"></span>U S & M I S S I O N</h2>
              <p>
                Voltique's vision is to pioneer the frontier of marketing and
                brand development by harnessing the transformative power of
                neuromarketing combined with our proprietary NeuroCom
                technology.
                <br /> We envision a future where every brand we touch
                transcends traditional marketing constraints, creating
                unparalleled emotional connections with their audiences. Our
                goal is to lead the industry in innovative solutions that not
                only elevate brands to their highest potential but also redefine
                the way businesses engage with consumers.
                <br /> By integrating deep psychological insights into our
                strategies, we aim to foster brands that are not merely
                recognized but revered, establishing a new standard for how
                brands communicate, connect, and grow within their market
                spaces.
                <br /> At the heart of Voltique's vision is the belief that in
                the crowded marketplace of tomorrow, only those brands that
                truly understand and resonate with their customers at an
                emotional level will lead and redefine their market space.
              </p>
            </div>
          </div>
          <div className={`flex-it ${helper(inView)}`}>
            <div className="section-2-home-image">
              <img src={SideImage} alt="image of person wearing VR" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2_home;
