import React from 'react'
import './Section3_home.css'
import { NavLink } from 'react-router-dom'
const Section3_home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className='section-3-home'>
        <h2>Contact Us Now & elevate your Brand</h2>
        <NavLink to="/contact" onClick={scrollToTop}><button>CONTACT US</button></NavLink>
    </section>
  )
}

export default Section3_home