import React, { useState } from "react";
import Logo from "../images/Voltique Brand Identity-6 1.png";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.css";
import { useLoading } from "../utils/LoadingContext";
import useNavigateWithLoading from "../utils/useNavigateWithLoading";
const Navbar = () => {
  const {isLoading,setisLoading} = useLoading();
  const [showLinks, setShowLinks] = useState(false);
  const location = useLocation();
  const isactiveHome = location.pathname === "/";
  const navigateWithLoading = useNavigateWithLoading();

  const activelink = ({ isActive }) => {
    return isActive ? "active-nav-link" : "";
  };

  const toggleMenu = () => {
    if (!showLinks) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.overflowX = "hidden";
    }
    setShowLinks(!showLinks);
  };
  return (
    <div className="navbar-2">
      <img src={Logo} alt="logo" />
      <div className="hamburger-icon">
        <i onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </i>
      </div>
      <div className={`nav-items ${showLinks ? "enabled" : "disabled"}`}>
        <NavLink
          to="/Voltique"
          className={({ isActive }) =>
            `${isActive || location.pathname === "/" ? "active-nav-link" : ""}`
          }
          onClick={() => {
            toggleMenu();
            navigateWithLoading("/Voltique");
          }}
        >
          Home Page
        </NavLink>
        <NavLink
          to="/about"
          className={activelink}
          onClick={() => {
            toggleMenu();
            navigateWithLoading("/about");
          }}
        >
          About Us
        </NavLink>
        <NavLink
          to="/contact"
          className={activelink}
          onClick={() => {
            toggleMenu();
            navigateWithLoading("/contact");
          }}
        >
          Contact Us
        </NavLink>
        <div className="cross-icon">
          <i onClick={toggleMenu} className=" cursor-pointer">
            <FontAwesomeIcon icon={faTimes} />
          </i>
        </div>
      </div>
      <div className="fake"></div>
    </div>
  );
};

export default Navbar;
