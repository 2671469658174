import React from "react";
import { Link } from "react-router-dom";

const Slide = ({ image, para, link }) => {
  return (
    <div className="services-slide">
      <div className="slide-image-container">
        <img className="slide-image" src={image} />
      </div>
      <div className="textBoxx">
      <h2 className="slide-h">{para}</h2>
      <Link to={link} className="slide-link">
        Show More
      </Link></div>
    </div>
  );
};

export default Slide;
